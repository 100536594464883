import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import LinkButton from "../common/LinkButton";
import Button from "../common/Button";
import Empty from "../common/Empty";
import { ip } from "./LoginForm";

const Div = styled.div`
  /* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
  @media screen and (max-width: 768px) {
    #empty {
      width: 100vw;
      height: 300px;
    }
    width: 100vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    form {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      input {
        width: 70vw;
        outline: none;
        margin: 10px 0;
        line-height: 2;
        border-radius: 10px;
        border: 1px solid #ccc;
        text-indent: 10px;
      }

      #btn {
        border-top: 1px dotted #ccc;
        padding-top: 20px;
        width: 250px;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }

  /* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
  @media screen and (min-width: 768px) {
    #empty {
      width: 100vw;
      height: 500px;
    }
    width: 250px;
    height: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    form {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      input {
        width: 200px;
        outline: none;
        margin: 10px 0;
        line-height: 2;
        border-radius: 10px;
        border: 1px solid #ccc;
        text-indent: 10px;
      }

      #btn {
        border-top: 1px dotted #ccc;
        padding-top: 20px;
        width: 250px;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
`;
const JoinForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    id: "",
    pw: "",
    pwCon: "",
  });

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData]
  );
  const handleJoin = async (e) => {
    e.preventDefault();

    //  입력된 값이 모두 채워져 있는지 확인
    if (
      !formData.name ||
      !formData.phone ||
      !formData.id ||
      !formData.pw ||
      !formData.pwCon
    ) {
      alert("모든 항목을 입력하세요.");
      return;
    }
    console.log(formData.pw, "dd", formData.pwCon);

    // 비밀번호와 비밀번호 확인이 일치하는지 확인
    if (formData.pw !== formData.pwCon) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }
    // 중복된 아이디 체크를 위해 서버에 요청
    try {
      const response = await axios.post(`https://${ip}/api/user/check`, {
        id: formData.id,
      });
      if (response.data.exists) {
        alert("이미 사용 중인 아이디입니다.");
        return;
      }
    } catch (error) {
      console.error("Error checking duplicate id:", error);
    }

    // 중복된 아이디가 없으면 회원 등록하기
    try {
      const response = await axios.post(
        `https://${ip}/api/user/join`,
        formData
      );
      console.log(response.data.message);
      alert("회원가입을 축하드립니다.");
    } catch (error) {
      console.error("Error fetching member data:", error);
    }
  };
  return (
    <Div>
      <h2>회원가입</h2>
      <form onSubmit={handleJoin}>
        <input
          onChange={handleChange}
          type="text"
          name="name"
          placeholder="이름을 입력하세요."
        />
        <input
          onChange={handleChange}
          type="text"
          name="phone"
          placeholder="연락처를 입력하세요."
        />
        <input
          onChange={handleChange}
          type="text"
          name="id"
          placeholder="아이디를 입력하세요."
        />
        <input
          onChange={handleChange}
          type="password"
          name="pw"
          placeholder="비밀번호를 입력하세요."
        />
        <input
          onChange={handleChange}
          type="password"
          name="pwCon"
          placeholder="비밀번호 확인"
        />

        <div id="btn">
          <Button>회원가입 하기</Button>
        </div>
      </form>
      <div id="empty"></div>
    </Div>
  );
};

export default JoinForm;
