import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { ip } from "../login/LoginForm";

const Div = styled.div`
  /* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
  @media screen and (max-width: 768px) {
    width: 90vw;
    #tableBox {
      margin-top: 3rem;
      text-align: center;
      justify-content: center;
      display: flex;
      td {
        width: 200px;
        height: 30px;
      }
      th {
        height: 35px;
      }
    }
  }

  /* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
  @media screen and (min-width: 768px) {
    width: 80%;
    #tableBox {
      margin-top: 3rem;
      text-align: center;
      justify-content: center;
      display: flex;
      td {
        width: 200px;
        height: 30px;
      }
      th {
        height: 35px;
      }
    }
    table {
      margin-right: 100px;
    }
    h2,
    h3 {
      margin-bottom: 10px;
    }
  }

  margin: 0 auto;

  table,
  td,
  th,
  tr {
    border: 1px solid #ccc;
    border-collapse: collapse;
  }
`;

const MemberDetail = () => {
  const localID = localStorage.getItem("id");
  const [userId, setUserId] = useState(localID); // 로컬 스토리지에서 userId 가져오기
  const { name } = useParams(); // URL 파라미터에서 이름 추출

  const [payList, setPayList] = useState([]);
  const [lessonList, setLessonList] = useState([]);
  const [memberPhone, setMemberPhone] = useState("");
  const [joinDate, setJoinDate] = useState("");
  useEffect(() => {
    fetchPayList();
    fetchLessonList();
    fetchMemberPhone();
  }, []);

  const fetchPayList = async () => {
    try {
      const response = await axios.get(
        `https://${ip}/api/payList/detail/${name}`,
        {
          params: { userId },
        }
      );
      const formattedPayList = response.data.map((pay) => ({
        ...pay,
        payDay: new Date(pay.payDay).toLocaleString("ko-KR", {
          timeZone: "Asia/Seoul",
        }),
      }));
      setPayList(formattedPayList);
    } catch (error) {
      console.error("Error fetching pay list:", error);
    }
  };

  const fetchLessonList = async () => {
    try {
      const response = await axios.get(
        `https://${ip}/api/lessonList/detail/${name}`,
        {
          params: { userId },
        }
      );
      const formattedLessonList = response.data.map((lesson) => ({
        ...lesson,
        lessonDay: new Date(lesson.lessonDay).toLocaleString("ko-KR", {
          timeZone: "Asia/Seoul",
        }),
      }));
      setLessonList(formattedLessonList);
    } catch (error) {
      console.error("Error fetching lesson list:", error);
    }
  };

  const fetchMemberPhone = async () => {
    try {
      const response = await axios.get(
        `https://${ip}/api/member/detail/${name}`,
        {
          params: { userId },
        }
      );
      setMemberPhone(response.data.phone);
      setJoinDate(
        new Date(response.data.joinDate).toLocaleString("ko-KR", {
          timeZone: "Asia/Seoul",
        })
      );
    } catch (error) {
      console.error("Error fetching member phone:", error);
    }
  };

  return (
    <Div>
      <h2>학원생 세부 정보</h2>
      <h3>{name} 님</h3>
      <p>연락처: {memberPhone}</p>
      <p>학원등록일: {joinDate}</p>
      <div id="tableBox">
        <table>
          <thead>
            <tr>
              <th>레슨 날짜</th>
            </tr>
          </thead>
          <tbody>
            {lessonList.map((lesson, index) => (
              <tr key={index}>
                <td>{lesson.lessonDay}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>결제일</th>
              <th>결제 금액</th>
            </tr>
          </thead>
          <tbody>
            {payList.map((payment, index) => (
              <tr key={index}>
                <td>{payment.payDay}</td>
                <td>{payment.pay}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Div>
  );
};

export default MemberDetail;
