import React, { useCallback, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import Button from "../common/Button";
import { useNavigate } from "react-router-dom";
export const ip = "haminsaxo.shop";
const Div = styled.div`
  /* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
  @media screen and (max-width: 768px) {
    width: 100vw;
    margin: 0 auto;
    input {
      width: 70vw;
      outline: none;
      margin: 10px 0;
      line-height: 3;
      border-radius: 10px;
      border: 1px solid #ccc;
      text-indent: 10px;
    }

    form {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  /* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
  @media screen and (min-width: 768px) {
    margin: 0 auto;
    width: 250px;
    height: 200px;

    input {
      width: 200px;
      outline: none;
      margin: 10px 0;
      line-height: 2;
      border-radius: 10px;
      border: 1px solid #ccc;
      text-indent: 10px;
    }
    form {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const LoginForm = () => {
  const navi = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    pw: "",
  });

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    [formData]
  );
  //로그인 하기
  const handleLogin = async (e) => {
    e.preventDefault();

    // 입력된 값이 모두 채워져 있는지 확인
    if (!formData.id || !formData.pw) {
      alert("모든 항목을 입력하세요.");
      return;
    }

    try {
      const response = await axios.post(
        `https://${ip}/api/user/login`,
        formData
      );
      console.log(response.data.message);
      // 로그인 성공 시 원하는 작업을 수행할 수 있습니다.
      alert("로그인 되었습니다.");
      // 로그인 정보를 로컬 스토리지에 저장
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("id", formData.id);
      navi("/");
    } catch (error) {
      console.error("Error logging in:", error);
      // 로그인 실패 시 에러 메시지를 확인하여 처리합니다.
      if (error.response && error.response.status === 401) {
        alert("아이디와 비밀번호가 일치하지 않습니다.");
      } else {
        alert("로그인에 실패했습니다.");
      }
    }
  };
  return (
    <Div>
      <h2>로그인</h2>
      <form onSubmit={handleLogin}>
        <input
          onChange={handleChange}
          type="text"
          name="id"
          placeholder="아이디를 입력하세요."
        />
        <input
          onChange={handleChange}
          type="password"
          name="pw"
          placeholder="비밀번호를 입력하세요."
        />
        <Button type="submit">로그인 하기</Button>
      </form>
    </Div>
  );
};

export default LoginForm;
