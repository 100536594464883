import React from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../common/Button";
import { ip } from "../login/LoginForm";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const Div = styled.div`
  /* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
  @media screen and (max-width: 768px) {
    width: 100vw;
    text-indent: 10px;
    #info {
      padding-right: 10px;
    }
    img {
      width: 90vw;
    }
  }

  /* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
  @media screen and (min-width: 768px) {
    width: 700px;
    margin: 0 auto;
    img {
      width: 500px;
    }
  }

  #box {
    display: flex;
    flex-direction: column;
  }
  #info {
    display: flex;
    justify-content: space-between;
    color: #454545;
    margin-bottom: 20px;
    font-size: 15px;
  }
  h2 {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  .content {
    margin: 50px 0;
  }
  #btns {
    display: flex;
    margin: 50px;
    justify-content: center;
  }
  a {
    text-decoration: none;
    color: black;
  }
`;
const Post = () => {
  const { postId } = useParams(); // URL 파라미터에서 이름 추출
  const [post, setPost] = useState(null); // 게시글 상태
  const auth = localStorage.getItem("id");
  const navi = useNavigate();
  useEffect(() => {
    if (postId) {
      // postId가 존재하는 경우에만 fetchPost 함수 호출
      const fetchPost = async () => {
        try {
          const response = await axios.get(
            `https://${ip}/api/posts/${Number(postId)}`
          );
          setPost(response.data);
        } catch (error) {
          console.error("Error fetching post:", error);
        }
      };

      fetchPost();
    }
  }, [postId]);
  const handleDelete = async () => {
    try {
      await axios.delete(`https://${ip}/api/delete/${Number(postId)}`);
      navi("/news"); // 삭제 후 목록 페이지로 이동
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };
  return (
    <Div>
      {/* 게시글을 로딩 중일 때 보여줄 UI */}
      {!post && <div>Loading...</div>}
      {/* 게시글을 로딩한 후에 보여줄 UI */}
      {post && (
        <div id="box">
          <h2>{post.title}</h2>
          <div id="info">
            <p>{post.id}</p>
            <p>{formatDate(post.writeDay)}</p>
          </div>

          <p
            className="content"
            dangerouslySetInnerHTML={{ __html: post.content }}
          ></p>
          {/* 이미지를 표시할 때 */}
          {post.imageUrl && <img src={post.imageUrl} alt="Post" />}
        </div>
      )}
      {auth ? (
        <div id="btns">
          <Button>
            <Link to={`/write/${postId}`}>수정하기</Link>
          </Button>
          <Button onClick={handleDelete}>삭제하기</Button>
          <Button>
            <Link to={"/news"}>목록</Link>
          </Button>
        </div>
      ) : (
        <div id="btns">
          {" "}
          <Button>
            <Link to={"/news"}>목록</Link>
          </Button>
        </div>
      )}
    </Div>
  );
};

export default Post;
