import React from "react";
import Wrapper from "../common/Wrapper";
import { styled } from "styled-components";
import Booking from "../book/Booking";
import { Link } from "react-router-dom";
const Div = styled.div`
  cursor: pointer;
  #head-logo {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    color: #ff8c00;
  }
  a {
    text-decoration: none;
  }
`;
const BookingPage = () => {
  return (
    <Wrapper>
      <Div>
        <Link to="/">
          <div id="head-logo">
            Lee Hamin
            <br />
            Saxophone Studio
          </div>
        </Link>
      </Div>
      <Booking />
    </Wrapper>
  );
};

export default BookingPage;
