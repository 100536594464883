import "./App.css";
import { Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import LoginPage from "./components/page/LoginPage";
import JoinPage from "./components/page/JoinPage";
import MainPage from "./components/page/MainPage";
import FacilityPage from "./components/page/FacilityPage";
import DirectorPage from "./components/page/DirectorPage";
import NewsPage from "./components/page/NewsPage";
import CurriculumPage from "./components/page/CurriculumPage";
import AddressPage from "./components/page/AddressPage";
import ManageMemberPage from "./components/page/ManageMemberPage";
import ManageLessonPage from "./components/page/ManageLessonPage";
import MemberDetailPage from "./components/page/MemberDetailPage";
import WritePage from "./components/page/WritePage";
import PostPage from "./components/page/PostPage";
import UsePage from "./components/page/UsePage";
import PersonPage from "./components/page/PersonPage";
import LessonTimePage from "./components/page/LessonTimePage";
import BookingPage from "./components/page/BookingPage";
import WatchBookPage from "./components/page/WatchBookPage";
function App() {
  return (
    <div className="App">
      <AnimatePresence>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/facility" element={<FacilityPage />} />
          <Route path="/director" element={<DirectorPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/curriculum" element={<CurriculumPage />} />
          <Route path="/address" element={<AddressPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/join" element={<JoinPage />} />
          <Route path="/managemember" element={<ManageMemberPage />} />
          <Route path="/managelesson" element={<ManageLessonPage />} />
          <Route path="/memberdetail/:name" element={<MemberDetailPage />} />
          <Route path="/write" element={<WritePage />} />
          <Route path="/write/:postId" element={<WritePage />} />
          <Route path="/post/:postId" element={<PostPage />} />
          <Route path="/use" element={<UsePage />} />
          <Route path="/person" element={<PersonPage />} />
          <Route path="/lessontime" element={<LessonTimePage />} />
          <Route path="/book" element={<WatchBookPage />} />
          <Route path="/makeBook" element={<BookingPage />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
